// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;
var subsiteHijackMainNav = ['thrombosis-australia-professionals' , 'thrombosis-australia'];
var subsiteHijackMainLogoClickDestination = ['thrombosis-australia-professionals' , 'thrombosis-australia'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

$(".footerBox[class*='ewsletter'] button").text("Subscribe");

// Header - Site Switcher
// $(".mainCallToAction .siteSwitcher").clone().prependTo(".menuAdminContainer #menuAdmin");

// Mobile nav - Site Switcher
// $(".menuMain .mainCallToAction .siteSwitcher").insertBefore(".menuMain ul.topLevel");

// subsite logo hack
$("body.subsite.thrombosis-australia-professionals a.mainLogo").attr("href" , "/thrombosis-australia-professionals");
$("body.subsite.thrombosis-australia a.mainLogo").attr("href" , "/thrombosis-australia");

// https://raisingit.atlassian.net/browse/DES-5740
if ($('.adminBarEdit').length) {
  $('.menuAdminContainer').css('position', 'relative');
}